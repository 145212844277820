import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import Layout from "../components/layout/Layout";

const smartContracts = [
    { name: "Lilith MD", address: "0x4d5811eAb844E4DFF73430C038676Ffb7Ed6B45D" },
    { name: "Bunny Love", address: "0x1AD9df9D133869d5F781D925f43DDedefC95f3D2" },
    { name: "Dumpling XO", address: "0xA3bcb1699F0806f63BAb35017D4984c9bcab4ce6" },
    { name: "DDesire", address: "0xE39DEcDf86005e3038a6E4434f13660B1D7eD211" },
    { name: "Lust", address: "0x36f78F633d3A2B678072b9213398393C51975d23" },
    { name: "Celestial Angels", address: "0xbf8CC2889d8c33af8EeC9a6812cD56466a095980" },
    { name: "Foxy", address: "0x730ac42Bd649634610aBCFE16Cd8fECf5Cc076Ef" },
    { name: "MoonMinters", address: "0xf3366f6519504371751483E8dbd1441efCEa0F6D" },
    { name: "Evil Pepe", address: "0x2d40065d60f4bC4a9d88DB65a47F3235Bd50e869" },
    { name: "Goetia Girls", address: "0x477125514532CD3B25cE0f15A8849736dE3a18FD" },
    { name: "Evil Pop", address: "0xe23fc5bd8285618Bd2fF47EF123B27DFb1eB02e9" },
    { name: "Submission", address: "0x0B760d5AF3a2ee18F4A61445D6AfE4F6F536b5C8" },
    { name: "Elf Waifus", address: "0x51eF02A78fb1FEE0CDAe5CfCfa65d73a506db50A" },
    { name: "Evil Frens", address: "0xC58d69Ac60BEd238f6DAE5b48c3f0A118422EaA9" },
    { name: "Antichrist", address: "0xf1DC07fd3F5e620512eDE97c81F06dfc8C52087A" },
    { name: "Bored Kongs", address: "0xE519Db6036d1bD7F31A6eD6f996680340dBFD9B0" },
];

const tokenAddress = "0x2528b12bf1b1b01a01f01b3ae9fbefc19e444dff";

const DepositRewardsContracts = () => {
    const [selectedContract, setSelectedContract] = useState(smartContracts[0].address);
    const [amount, setAmount] = useState("");
    const [loading, setLoading] = useState(false);
    const [balance, setBalance] = useState("0");
    const [walletBalance, setWalletBalance] = useState("0");

    useEffect(() => {
        const fetchBalance = async () => {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const tokenContract = new ethers.Contract(
                tokenAddress,
                ["function balanceOf(address) view returns (uint256)"],
                provider
            );
            const contractBalance = await tokenContract.balanceOf(selectedContract);
            setBalance(parseFloat(ethers.utils.formatEther(contractBalance)).toFixed(2));

            // Fetch wallet balance
            const accounts = await provider.listAccounts();
            const walletAddress = accounts[0];
            const walletBalance = await tokenContract.balanceOf(walletAddress);
            setWalletBalance(parseFloat(ethers.utils.formatEther(walletBalance)).toFixed(2));
        };

        fetchBalance();
    }, [selectedContract]);

    const handleDeposit = async () => {
        if (!amount || isNaN(amount)) {
            alert("Please enter a valid amount.");
            return;
        }

        try {
            setLoading(true);
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();

            // Approve the token spending
            const tokenContract = new ethers.Contract(
                tokenAddress,
                ["function approve(address spender, uint256 amount) public returns (bool)"],
                signer
            );

            const approveTx = await tokenContract.approve(selectedContract, ethers.utils.parseEther(amount));
            await approveTx.wait();

            // Interact with the selected contract
            const rewardContract = new ethers.Contract(
                selectedContract,
                ["function depositRewardTokens(uint256 _amount) payable"],
                signer
            );

            const depositTx = await rewardContract.depositRewardTokens(ethers.utils.parseEther(amount), {
                value: ethers.utils.parseEther("0"), // Adjust if a payable amount is required
            });

            await depositTx.wait();
            alert("Deposit successful!");
        } catch (error) {
            console.error(error);
            alert("An error occurred: " + error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Layout title="Deposit Rewards | Stake">
            <div style={{ paddingTop: "10rem", paddingBottom: "10rem" }} className="flex items-center justify-center h-screen">
                <div className="p-4 bg-gray-100 rounded-lg shadow-md w-full max-w-md">
                    <h2 className="text-xl font-bold mb-4 text-white text-center">Deposit Rewards</h2>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                        <div className="mb-4">
                            <div className="mb-2 text-center">
                                <span className="block text-sm font-medium text-white">Contract Balance: {balance}</span>
                            </div>
                        </div>
                        <select
                            id="contract"
                            className="w-full mt-1 p-2 border border-[#212121] rounded-[5px] bg-transparent text-white"
                            value={selectedContract}
                            onChange={(e) => setSelectedContract(e.target.value)}
                            style={{ borderRadius: '5px' }}
                        >
                            {smartContracts.map((contract) => (
                                <option key={contract.address} value={contract.address} className="text-black">
                                    {contract.name}
                                </option>
                            ))}
                        </select>
                        <div className="mb-4" style={{ marginTop: '30px' }}>
                            <label htmlFor="amount" className="block text-sm font-medium text-white text-center">
                                Amount to Deposit
                            </label>
                        </div>
                        <div>
                            <input
                                type="text"
                                id="amount"
                                className="w-full mt-1 p-2 border border-[#212121] rounded-[5px] bg-transparent text-white"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                                style={{ borderRadius: '5px' }}
                            />
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            <button
                                className={`w-full p-2 rounded-md text-white ${loading ? "bg-gray-400" : "bg-blue-500 hover:bg-blue-600"}`}
                                onClick={handleDeposit}
                                disabled={loading}
                                style={{ border: "1px solid white", width: "150px", borderRadius: '5px' }}
                            >
                                {loading ? "Processing..." : "Deposit"}
                            </button>
                        </div>
                        {/* Display Wallet Balance below the button */}
                        <div className="mt-4">
                            <span className="block text-sm font-medium text-white">
                                Wallet Balance: {walletBalance}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default DepositRewardsContracts;
